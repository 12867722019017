import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
export const query = graphql`
  {
    site {
      siteMetadata {
        author
        siteDesc: description
        image
        siteUrl
        siteTitle: title
        twitterUsername
      }
    }
  }
`;
const SEO = ({ title, description }) => {
  const { site } = useStaticQuery(query);

  const { siteDesc, siteTitle, siteUrl } = site.siteMetadata;

  return (
    <Helmet htmlAttributes={{ lang: "en" }} title={`${title} | ${siteDesc}`}>
      <meta name="description" content={description}></meta>
      <meta name="site URL" content={siteUrl} />
      <meta name="site Title" content={siteTitle} />
    </Helmet>
  );
};

export default SEO;
